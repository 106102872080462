<template lang="html">
  <div class="">



      <div :style="{fontWeight:'bold',marginTop:'2%', marginLeft:'2%',textAlign:'left',fontSize:'22px', height:'29px',color: '#11BA6C'}">
        Välj kategori
      </div>
      <div class="">
        Vald kategori: {{this.main_category_svenska}}<br>
        Totalt vald: {{this.new_category}}
      </div>


      <div :style="{marginTop:'1%',border: '2px solid #11BA6C', borderRadius:'8px',marginLeft:'4%',
                    width:'90%',height:'150px', overflowY:'auto'}">
        <div v-for="(kategori,idx) in kategori_lista"
            :key="idx"
            :style="{marginLeft:'1%' ,textAlign:'left',  width:'90%',height:'15px', fontSize:'14px', marginTop:'0%'}">
          <div :style="{width:'100%',height:'15px', display:'flex',marginTop:'0%'}"
               @click="choose_category(kategori)">
            <div :style="{marginLeft:'1%',marginTop:'0%'}">
              {{kategori.main_category_svenska}}
            </div>
          </div>
        </div>
      </div>

      <input type="text"  name=""  v-model="kategori_svenska" value="" />
      <button type="button" @click="add_swedish_category()" name="button"> Add svensk kategori</button>
      <input type="text"  name=""  v-model="kategori_engelska" value="" />
      <button type="button" @click="add_english_category()" name="button"> Add engelsk kategori</button><br>

      <input type="text" name="" placeholder="ändra värde" value="">
      <div :style="{height:'20px'}">

      </div>
      <button type="button" :style="{color:'green'}" @click="create_category()" name="button">Add category</button>

  </div>
</template>

<script>
import axios from 'axios';
import {API_URL} from '../../config.js'

// import Navigation from '../components/Navigation.vue';
export default {
  data:function(){
    return{
      kategori_svenska:'',
      kategori_engelska:'',
      added:false,
      main_category_svenska:'',
      kategori_lista:[],
      new_category:{}
    }

  },
  methods:{
    choose_category(kategori){
      this.main_category_svenska =kategori.main_category_svenska
      this.new_category["main_category_engelska"] = kategori.main_category_engelska
      this.new_category["main_category_svenska"] = kategori.main_category_svenska

    },
    add_swedish_category(){
      this.new_category["category_svenska"] = this.kategori_svenska

    },
    add_english_category(){
            this.new_category["category_english"] = this.kategori_engelska
    },
    create_category(){
      var self = this
      axios({
         method:'post',
         url: API_URL+ '/add_category',
         data:{new_category:this.new_category }
       })
       .then(function(){
            self.$alert("Added")

       })

    },
    get_category_to_add_func(){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_category_to_add',
        })
        .then(function(response){

            self.kategori_lista=response.data
        })

    }


  },
  mounted(){
    this.get_category_to_add_func()
  },
  components:{
    //Navigation
  }


}
</script>

<style lang="css" scoped>
</style>
